import React, { Component } from 'react'

import TodoItem from './TodoItem'
import AddTodo from './AddTodo'

export default class TodoList extends Component {
    constructor (props) {
        super(props)

        this.state = {
            todos: [],
            hasNewTodo: false,
            loading: false
        }
    }

    componentWillMount () {
        fetch('https://jules-app.herokuapp.com', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ query: `{ todos { id task done } }`})
        })
        .then(res => res.json())
        .then(({ data: { todos } }) => {
            this.setState({
                todos,
                loading: false
            })
        })
        .catch(err => console.error(err))
    }

    addTodo (task) {
        fetch('https://jules-app.herokuapp.com', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                query: `mutation {
                    addTodo(task: "${task}") { 
                        id 
                        task 
                        done
                    } 
                }`
            })
        })
        .then(res => res.json())
        .then(({ data: { addTodo } }) => {
            this.setState({
                todos: [...this.state.todos, addTodo],
                hasNewTodo: false
            })
        })
        .catch(err => console.error(err))
    }

    updateTodo ({ id, task }) {
        fetch('https://jules-app.herokuapp.com', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                query: `
                    mutation {
                        updateTodo (data: { id: "${id}" task: "${task}"}) {
                            id
                        }
                    }
                `
            })
        })

        this.setState({
            todos: this.state.todos.map((_todo) => {
                if (id === _todo.id)
                    _todo.task = task

                return _todo
            })
        })
    }

    doneTodo ({ id }) {
        const index = this.state.todos.map(todo => todo.id).indexOf(id)

        const newTodos = this.state.todos
        newTodos[index].done = !newTodos[index].done

        fetch('https://jules-app.herokuapp.com', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                query: `
                    mutation {
                        updateTodo (data: { id: "${id}" done: ${newTodos[index].done}}) {
                            id
                        }
                    }
                `
            })
        })
        
        this.setState({
            todos: newTodos
        })
    }

    deleteTodo ({ id, task }) {
        const index = this.state.todos.map(todo => todo.task).indexOf(task)

        fetch('https://jules-app.herokuapp.com', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                query: `
                    mutation {
                        deleteTodo (id: "${id}") {
                            id
                        }
                    }
                `
            })
        })

        this.setState({
            todos: this.state.todos.filter((_, i) => index !== i)
        })
    }

    toggleNewTodo () {
        this.setState({
            hasNewTodo: !this.state.hasNewTodo
        })
    }

    render () {

        if (this.state.loading)
            return (
                <div className="todo-list">
                    <div className="todo-item">Loading...</div>
                </div>
            )
        else
            return (
                <div className="todo-list">
                    { this.state.todos.map((_todo, i) => (
                        <TodoItem 
                            key={_todo.id}
                            id={_todo.id}
                            task={_todo.task}
                            done={_todo.done}
                            doneTodo={this.doneTodo.bind(this)}
                            updateTodo={this.updateTodo.bind(this)}
                            deleteTodo={this.deleteTodo.bind(this)}
                        />
                        )) 
                    }
                    <AddTodo show={this.state.hasNewTodo} addTodo={this.addTodo.bind(this)}/>
                    <div className={`open-add-todo ${this.state.hasNewTodo ? 'open' : ''}`} onClick={this.toggleNewTodo.bind(this)}>
                        <span>+</span>
                    </div>
                </div>
            )
    }
}