import React, { Component } from 'react'

export default class Checkbox extends Component {
    render () {
        return (
            <div className="checkbox-container" onClick={this.props.onClick}>
                <svg width="40" height="40" viewBox="0 0 40 40">
                    <circle r="10" cx="20" cy="20" className="back-circle" />
                    <circle r="10" cx="20" cy="20" className={`done-circle ${this.props.done ? 'done' : ''}`} />
                </svg>
            </div>
        )
    }
}