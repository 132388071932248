import React, { Component } from 'react'

export default class Todo extends Component {
    render () {
        const date = new Date()
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

        const month = monthNames[date.getMonth()].substr(0, 3)
        let dateNumber = date.getDate()
        dateNumber = dateNumber.toString().length < 2 ? `0${dateNumber}` : dateNumber
        return (
            <header>
                <div className="date">
                    <div className="day-number"> { dateNumber } </div>
                    <div className="month-year">
                        <div className="month"> { month } </div>
                        <div className="year"> { date.getFullYear() } </div>
                    </div>
                </div>
                <div className="day">
                    { dayNames[date.getDay()] }
                </div>
            </header>
        )
    }
}