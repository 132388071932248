import React, { Component } from 'react'

import './App.css'

import TodoList from './todo/TodoList'
import Header from './header/Header'

class App extends Component {
  render() {
    return (
        <div className="App">
          <Header />
          <TodoList />
        </div>
    )
  }
}

export default App
