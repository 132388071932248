import React, { Component } from 'react'
import Checkbox from './Checkbox';

export default class TodoItem extends Component {

    constructor (props) {
        super(props)
        this.state = {
            task: props.task
        } 
    }

    action (_action) {
        // const i = parseInt(this._reactInternalFiber.key)
        this.props[`${_action}Todo`]({ id: this.props.id, task: this.state.task })
    }

    updateTask (task) {
        this.setState({
            task
        })
    }

    render () {
        return (
            <div className="todo-item">
                <div className="delete" onClick={() => this.action('delete')}>
                    <span>+</span>
                </div>
                <input 
                    className={`task ${this.props.done ? 'done' : ''}`}
                    value={this.state.task}
                    onChange={_e => this.updateTask(_e.target.value)}
                    onBlur={_e => this.action('update')}
                />
                <Checkbox done={this.props.done} onClick={() => this.action('done')}/>
            </div>
        )
    }
}