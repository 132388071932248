import React, { Component } from 'react'

export default class AddTodo extends Component {

    constructor (props) {
        super(props)
        this.input = React.createRef()
    }

    addTodo (_e) {
        const task = this.input.current.value
        if (task === 0)
            return

        if (_e.charCode === 13) {
            this.props.addTodo(task)
            this.input.current.value = ''
        }
        else if (!_e.charCode) {
            this.props.addTodo(task)
            this.input.current.value = ''
        }

    }

    render () {
        return (
            <div className={`new-todo ${this.props.show ? 'open' : ''}`}>
                <div className="scale" />
                <div className="add">
                    <input ref={this.input} type="text" onKeyPress={this.addTodo.bind(this)}/>
                    <button onClick={this.addTodo.bind(this)}>Add</button>
                </div>
            </div>
        )
    }
}